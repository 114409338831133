import React, {useEffect, useState} from "react";
import {ImageBackground, ScrollView, StyleSheet, TouchableWithoutFeedback } from "react-native";
import {Box, Divider, HStack, Image, Text, VStack} from "native-base";
import {CartItem, MenuListData, OptionItemData, OptionListData} from "./lib/model";
import apps, {Happyhour, SaleMode} from "./lib/apps";
import {useRecoilState} from "recoil";
import {cartState} from "./state/cartState";
import {useNavigation} from "@react-navigation/native";
import {menuUri} from "./lib/utils";
import {showMessage} from "react-native-flash-message";

function Price({saleMode, menu}: { saleMode: SaleMode, menu: MenuListData }) {
    if (apps.isSale(saleMode, menu)) {
        return (
            <HStack>
                <Text style={styles.menuPriceStroked}>${menu.price}</Text>
                <Text style={styles.specialPrice}>${menu.priceSp}</Text>
            </HStack>
        );
    }

    return (
        <Text style={styles.menuPrice}>${menu.price}</Text>
    );
}

function MenuBase({saleMode, menu}: { saleMode: SaleMode, menu: MenuListData }) {
    return (
        <>
            <Divider style={styles.dividor}/>
            <VStack style={styles.menuBaseContainer}>
                <HStack>
                    {apps.isSale(saleMode, menu) && <Image source={require("../assets/icon_sale.png")} alt="sale"
                                                           w={10} h={18} ml={0} mt={0.5} mr={1} resizeMode="stretch"
                    />}
                    <Text style={styles.menuName}>{menu.name}</Text>
                    <Price saleMode={saleMode} menu={menu}/>
                </HStack>
                <Text style={styles.menuDescr}>{menu.descr}</Text>
            </VStack>
            <Divider style={styles.dividor}/>
        </>
    );
}

function MenuImage({menuUri, goBack}: { menuUri: { uri: string }, goBack: () => void }) {
    return <ImageBackground source={menuUri} style={styles.menuImage} resizeMode="cover">
        <TouchableWithoutFeedback onPress={() => {
            goBack();
        }}>
            <Image source={require("../assets/btn_x.png")} alt="x" style={styles.cancelBtn}/>
        </TouchableWithoutFeedback>
    </ImageBackground>;
}

function MenuOption({option, optionSelected, onUpdate}:
                        {
                            option: OptionListData, optionSelected: OptionItemData[],
                            onUpdate: (option: OptionListData, optionItems: OptionItemData[]) => void
                        }) {
    const [selected, setSelected] = useState<OptionItemData[]>(optionSelected == undefined ? [] : optionSelected);

    function selectSingle(optionItem: OptionItemData) {
        const sel = [optionItem];
        onUpdate(option, sel)
        setSelected(sel);
    }

    function selectMultiple(optionItem: OptionItemData) {
        let sel = []
        if (selected.find(o => optionItem.optionItemId == o.optionItemId)) {
            sel = selected.filter(o => optionItem.optionItemId != o.optionItemId);
        } else {
            sel = [...selected, optionItem];
        }
        onUpdate(option, sel)
        setSelected(sel);

        console.log(optionItem);
        console.log(sel);
    }

    function resetSelect() {
        setSelected([]);
        onUpdate(option, [])
    }

    return <>
        <HStack style={styles.optionList}>
            <Text style={styles.optionTitle}>{option.descr}</Text>

            {option.optional ?
                <TouchableWithoutFeedback onPress={() => resetSelect()}>
                    <Text style={styles.optionRequired}> X </Text>
                </TouchableWithoutFeedback>
                :
                <>
                    <Text style={styles.optionRequired}>Required</Text>
                    <Image source={require("../assets/icon_required.png")} alt="required"
                           style={styles.optionRequiredIcon} resizeMode="contain"/>
                </>}

        </HStack>
        <Box bgColor="white">
            {option.items.map((item, index) =>
                <HStack key={index}
                        style={index < option.items.length - 1 ? styles.optionItem : styles.optionItemWithoutBottom}>

                    {option.multiple ?
                        selected.includes(item) ?
                            // 이유는 모르겠지만.. image, text를 touch로 한번에 묶으면 image가 바뀌지 않는다. 그래서 각각을 touch로.
                            <HStack style={styles.optionItemInside}>
                                <TouchableWithoutFeedback onPress={() => {
                                    selectMultiple(item);
                                }}>
                                    <Image source={require("../assets/check_on.png")} alt="on" h="26px" w="26px"/>
                                </TouchableWithoutFeedback>
                                <TouchableWithoutFeedback onPress={() => {
                                    selectMultiple(item);
                                }}>
                                    <Text style={styles.optionItemLabel}>{item.name}</Text>
                                </TouchableWithoutFeedback>
                            </HStack>
                            :
                            <TouchableWithoutFeedback onPress={() => {
                                selectMultiple(item);
                            }}>
                                <HStack style={styles.optionItemInside}>
                                    <Image source={require("../assets/check_off.png")} alt="on" h="26px" w="26px"/>
                                    <Text style={styles.optionItemLabel}>{item.name}</Text>
                                </HStack>
                            </TouchableWithoutFeedback>

                        :
                        selected.includes(item) ?
                            <HStack style={styles.optionItemInside}>
                                <Image source={require("../assets/radio_on.png")} alt="on" h="26px" w="26px"/>
                                <Text style={styles.optionItemLabel}>{item.name}</Text>
                            </HStack>
                            :
                            <TouchableWithoutFeedback onPress={() => {
                                selectSingle(item)
                            }}>
                                <HStack style={styles.optionItemInside}>
                                    <Image source={require("../assets/radio_off.png")} alt="off" h="26px" w="26px"/>
                                    <Text style={styles.optionItemLabel}>{item.name}</Text>
                                </HStack>
                            </TouchableWithoutFeedback>
                    }


                    {item.price != 0 &&
                        <Text
                            style={styles.optionItemPrice}>{item.price > 0 ? '+$' : '-$'}{item.price.toFixed(1)}</Text>
                    }
                </HStack>
            )}
        </Box>
    </>;
}

function OptionList({options, optionsSelected, onUpdate}: {
    options: OptionListData[],
    optionsSelected: Map<OptionListData, OptionItemData[]>,
    onUpdate: (option: OptionListData, optionItems: OptionItemData[]) => void
}) {
    return (
        <Box style={{flex: 1, backgroundColor: "#f7f5f2", width: "100%", overflow: "scroll"}}>
            {options.map((option: OptionListData, index) =>
                <MenuOption key={index} option={option} optionSelected={optionsSelected.get(option)}
                            onUpdate={onUpdate}/>
            )}

            {options.length > 0 ? <Divider style={styles.dividor}/> : ''}
        </Box>
    );
}

const AddButton: React.FC<{ menu: MenuListData, onAdd: () => void, total: number }> = ({menu, onAdd, total}) => {
    return (
        <Box bgColor="light.500" style={{width: "100%"}}>
            <Box style={styles.addButton}>
                <TouchableWithoutFeedback onPress={() => onAdd()}>
                    <HStack style={styles.addButtonStack}>
                        <Text style={styles.addButtonLabel}>Add To Order</Text>
                        <Image source={require("../assets/bracket_HL.png")} alt="lb" h="22px" w="7px"
                               mt="1px" mr="3px"/>
                        <Text style={styles.addButtonPrice}>${total}</Text>
                        <Image source={require("../assets/bracket_HR.png")} alt="lb" h="22px" w="7px"
                               mt="1px" ml="3px"/>
                    </HStack>
                </TouchableWithoutFeedback>
            </Box>
        </Box>
    );
};

const UpdateButton: React.FC<{
    menu: MenuListData, onEdit: () => void, onRemove: () => void, qty: number, total: number,
    onQtyUpdated: (qty: number) => void
}> = ({menu, onEdit, onRemove, qty, total, onQtyUpdated}) => {
    return (
        <VStack style={styles.updateButton}>
            <HStack style={{
                width: "100%", height: 55, justifyContent: "space-around",
                paddingLeft: 20, paddingRight: 20, paddingTop: 20, paddingBottom: 10,
            }}>
                <HStack style={{}}>
                    <TouchableWithoutFeedback onPress={() => {
                        onQtyUpdated(qty > 1 ? qty - 1 : 1)
                    }}>
                        <Box style={{
                            width: 40, height: 40, padding: 7, borderRadius: 15, backgroundColor: "#434343"
                        }}>
                            <Image src={require("../assets/icon_minus.png")} alt="minus" width={26} height={26}/>
                        </Box>
                    </TouchableWithoutFeedback>
                    <Text style={{
                        fontFamily: "NotoSans-Regular",
                        color: "#434343",
                        fontWeight: "600",
                        fontSize: 18,
                        letterSpacing: -0.6,
                        marginLeft: 15, marginRight: 15, marginTop: 5
                    }}>{qty}</Text>
                    <TouchableWithoutFeedback onPress={() => {
                        onQtyUpdated(qty < 49 ? qty + 1 : 49)
                    }}>
                        <Box style={{
                            width: 40, height: 40, padding: 7, borderRadius: 15, backgroundColor: "#434343"
                        }}>
                            <Image src={require("../assets/icon_plus.png")} alt="minus" width={26} height={26}/>
                        </Box>
                    </TouchableWithoutFeedback>
                </HStack>
                <TouchableWithoutFeedback onPress={() => {
                    onRemove();
                }}>
                    <HStack style={{
                        width: 120, height: 40, padding: 7, borderRadius: 15, backgroundColor: "#999",
                        justifyContent: "center"
                    }}>
                        <Text style={{
                            fontFamily: "NotoSans-Regular",
                            color: "#fff",
                            fontWeight: "900",
                            fontSize: 15,
                            letterSpacing: -0.38,
                            marginLeft: 5, marginRight: 7, marginTop: 2
                        }}>Remove</Text>
                            <Image src={require("../assets/icon_remove.png")} alt="minus" width={26} height={26}/>
                    </HStack>
                </TouchableWithoutFeedback>
            </HStack>

            <TouchableWithoutFeedback onPress={() => onEdit()}>
                <HStack style={styles.addButtonStack}>
                    <Text style={styles.addButtonLabel}>Update Cart</Text>
                    <Image source={require("../assets/bracket_HL.png")} alt="lb" h="22px" w="7px"
                           mt="1px" mr="3px"/>
                    <Text style={styles.addButtonPrice}>${total}</Text>
                    <Image source={require("../assets/bracket_HR.png")} alt="lb" h="22px" w="7px"
                           mt="1px" ml="3px"/>
                </HStack>
            </TouchableWithoutFeedback>
        </VStack>
    );
};

function Menu({route}) {
    const [cart, setCart] = useRecoilState(cartState);
    const happyhour: Happyhour = apps.getHappyhour();
    const saleMode: SaleMode = happyhour.saleMode;

    /** happyhour salemode가 shop을 시작할 때와 달라졌으면 팝업 안내를 표시하고 다시 처음부터 주문을 하게 한다. **/
    if ((apps.saleMode == SaleMode.HAPPY1 || apps.saleMode == SaleMode.HAPPY2) &&
        saleMode == SaleMode.NORMAL) {

        const happyhourDescr = apps.saleMode == SaleMode.HAPPY1 ? apps.currentShop.happy1Descr : apps.currentShop.happy2Descr;
        alert(`${happyhourDescr} has ended. Please reorder from the beginning` );

        location.reload();
    }

    const selected: CartItem = route.params == undefined ? null : cart.items.find(i => i.tag() == route.params.selectedTag);

    // console.log("selected: " + selected);

    const currentShop = apps.currentShop!!;
    const menu = selected == null ? apps.selectedMenu!! : selected.menu;
    const navigation = useNavigation<any>();
    const [isAdd, setIsAdd] = useState(false);  // add button click 했는지
    const [optionSelected, setOptionSelected] =
        useState<Map<OptionListData, OptionItemData[]>>(selected == null ? new Map() : selected.options); // 선택된 옵션
    const [total, setTotal] = useState<number>(selected == null ? apps.getPrice(saleMode, menu) : selected.itemPrice * selected.qty); // 총 가격
    const [qty, setQty] = useState<number>(selected == null ? 1 : selected.qty);    // 수량

    // console.log(menu);

    const options = apps.toOptions(menu.optionIds);
    // console.log(options);

    useEffect(() => {
            if (isAdd) {
                console.log(cart);
                // showMessage({ message: `cart count: ${cart.count}` });
                navigation.replace("Cart")
            }
            return () => {
                setIsAdd(false);
            }
        }, [isAdd],
    );

    const onAdd = () => {
        // 아래 두 가지 작업(에러 메시지 출력과 에러가 있는지 체크해서 리턴)을 한번에 할 수 없을까
        options.forEach((o: OptionListData) => {
            if (o.optional == false && optionSelected.get(o) == undefined) {
                showMessage({
                    message: "Please select " + o.descr,
                    type: "danger",
                    duration: 1000
                });
            }
        });
        if (options.find((o: OptionListData) => o.optional == false && optionSelected.get(o) == undefined) != undefined) return;

        // happyhour only check
        if ((saleMode != SaleMode.HAPPY1 && menu.happy1Only) ||
            (saleMode != SaleMode.HAPPY2 && menu.happy2Only)) {
            showMessage({
                message: `You can order this menu ony at ${apps.getHappyhourDescr(menu.happy1Only ? SaleMode.HAPPY1 : SaleMode.HAPPY2)} ${menu.happy1Only ? apps.getHappy1Time() : apps.getHappy2Time()}`,
                type: "danger",
                duration: 2000
            });
            return;
        }

        setCart(cart.addItemWithShop(saleMode, menu, optionSelected, qty, apps.currentShop!!));
        setIsAdd(true);
    };

    const onEdit = () => {
        setCart(cart.updateItemWithShop(menu, optionSelected, qty, apps.currentShop!!));
        setIsAdd(true);
    };

    const onRemove = () => {
        setCart(cart.removeItem(selected.tag()));

        apps.selectedMenu = null;
        navigation.goBack()
    }

    const updateOption = (option: OptionListData, optionItems: OptionItemData[]) => {
        if (optionItems.length == 0) {
            optionSelected.delete(option)
            setOptionSelected(optionSelected);
        } else {
            setOptionSelected(optionSelected.set(option, optionItems));
        }

        let total = apps.getPrice(saleMode, menu);
        optionSelected.forEach((optionItems, option) =>
            total += optionItems.reduce((acc, cur) => acc + cur.price, 0)
        );

        setTotal(total);
    }

    const onQtyUpdated = (qty) => {
        setQty(qty);

        let total = apps.getPrice(saleMode, menu);
        optionSelected.forEach((optionItems, option) =>
            total += optionItems.reduce((acc, cur) => acc + cur.price, 0)
        );

        setTotal(total * qty);
    }

    return (
        <VStack style={styles.container}>

            <ScrollView style={{flex: 1, width: "100%", overflow: "scroll"}}>
                <MenuImage menuUri={menuUri(currentShop, menu)} goBack={() => {
                    apps.selectedMenu = null;
                    navigation.goBack()
                }}/>
                <MenuBase saleMode={saleMode} menu={menu}/>
                <OptionList options={options} optionsSelected={optionSelected} onUpdate={updateOption}/>
            </ScrollView>

            {selected == null ?
                <AddButton menu={menu} onAdd={onAdd} total={total}/>
                :
                <UpdateButton menu={menu} onEdit={onEdit} onRemove={onRemove} qty={qty} total={total} onQtyUpdated={onQtyUpdated}/>
            }
        </VStack>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    menuImage: {
        height: 300,
        width: "100%",
        justifyContent: "flex-end",
        alignItems: "flex-end",
    },
    cancelBtn: {
        height: 28,
        width: 28,
        margin: 10,
    },
    optionList: {
        height: 44, paddingLeft: 20, paddingRight: 20,
        paddingTop: 14, backgroundColor: "#f7f5f2",
        borderTopWidth: 1, borderBottomWidth: 1, borderColor: "#dbd3c6",
    },
    optionTitle: {
        fontSize: 13, fontWeight: "bold", flex: 1, marginTop: 2,
    },
    optionRequired: {
        fontSize: 13, fontWeight: "normal", marginTop: 2, color: "#db146d",
    },
    optionRequiredIcon: {
        width: 26, height: 26, marginLeft: 3,
    },
    optionItem: {
        height: 39, marginLeft: 21, marginRight: 20, alignItems: "center",
        borderBottomWidth: 1, borderBottomColor: "#e9e4dd", flex: 1,
    },
    optionItemWithoutBottom: {
        height: 39, marginLeft: 21, marginRight: 20, alignItems: "center", flex: 1,
    },
    optionItemInside: {
        height: 39, alignItems: "center", flex: 1,
    },
    optionItemLabel: {flex: 1, fontSize: 13, color: "#666"},
    optionItemPrice: {fontSize: 13, color: "#999"},
    optionItemLabelSelected: {flex: 1, fontSize: 13, color: "#434343"},
    dividor: {height: 1, backgroundColor: "#e9e4dd"},
    addButton: {
        backgroundColor: "white",
        width: "100%", height: 110,
        borderTopLeftRadius: 30,
        borderTopRightRadius: 30,
        borderWidth: 1,
        borderColor: "#dbd3c6",
    },
    updateButton: {
        backgroundColor: "white",
        width: "100%", height: 160,
        borderTopLeftRadius: 30,
        borderTopRightRadius: 30,
        borderWidth: 1,
        borderColor: "#dbd3c6",
    },
    addButtonStack: {
        backgroundColor: "#db146d",
        borderRadius: 20,
        margin: 20,
        height: 68,
        marginLeft: 20,
        marginRight: 20,
        alignItems: "center",
        padding: 20,
    },
    addButtonLabel: {
        flex: 1, fontSize: 18, fontWeight: "bold", color: "white",
    },
    addButtonPrice: {
        fontSize: 18, fontWeight: "bold", color: "white",
    },
    menuBaseContainer: {
        marginTop: 15, paddingLeft: 20, paddingRight: 20, backgroundColor: "white", width: "100%",
    },
    menuName: {fontSize: 16, fontWeight: "600", color: "#434343", letterSpacing: -0.9, flex: 1},
    menuPrice: {fontSize: 16, color: "#db146d", letterSpacing: -0.4},
    menuDescr: {marginTop: 0, marginBottom: 10, fontSize: 12, color: "#999", letterSpacing: -0.3, lineHeight: 17},
    menuPriceStroked: {
        fontFamily: "NotoSans-Regular",
        color: "#999",
        fontSize: 16,
        textDecorationLine: "line-through"
    },
    specialPrice: {
        marginLeft: 5,
        fontFamily: "NotoSans-Regular",
        fontWeight: "700",
        color: "red",
        fontSize: 16,
    },
});

export default Menu;
